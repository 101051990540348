<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <form class="row justify-content-between">
          <div class="col-md-2">
            <v-select
              label="title"
              required
              v-model="perPage"
              :options="perPageOptions"
              @input="setPerPageSelected"
              :clearable="false"
            />
          </div>
          <div class="col-md-4">
            <input
              type="search"
              class="d-inline-block form-control"
              placeholder="Pesquisar..."
              v-model="filter"
            />
          </div>
        </form>
      </div>

      <b-table
        :sticky-header="true"
        :no-border-collapse="true"
        class="position-relative"
        :items="managers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Nenhum registro encontrado"
        empty-filtered-text="Nenhum registro encontrado"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        striped
        hover
      >
        <template #cell(created_at)="data">
          {{ data.item.created_at | datePT }}
        </template>

        <template #cell(units)="data">
          <div class="d-flex-center">
            <b-avatar-group
              size="26"
              class="ml-1"
              v-if="data.item.units.length > 1"
            >
              <b-avatar
                v-for="(unit, i) in data.item.units"
                :key="i"
                triggers="hover"
                class="pull-up"
                variant="warning"
                v-b-tooltip.hover.top="`${unit.name}`"
              >
                {{ getFirstLetters(unit.name) }}
              </b-avatar>
            </b-avatar-group>

            <div v-else>
              <b-avatar
                v-for="(unit, i) in data.item.units"
                :key="i"
                triggers="hover"
                class="pull-up"
                variant="warning"
                v-b-tooltip.hover.top="`${unit.name}`"
              >
                {{ getFirstLetters(unit.name) }}
              </b-avatar>
            </div>
          </div>
        </template>

        <template #cell(full_name)="data">
          <div
            class="d-flex-left"
            v-b-tooltip.hover.top="data.item.active ? 'Ativo' : 'Inativo'"
          >
            <i
              style="font-size: 0.7rem"
              class="bi bi-circle-fill mr-50"
              :class="data.item.active ? 'text-success' : 'text-danger'"
            ></i>
            <span>
              {{ data.item.full_name }}
            </span>
          </div>
        </template>

        <template #cell(categories)="data">
          <div class="d-flex-center">
            <b-avatar-group size="26" class="ml-1">
              <b-avatar
                v-for="(category, i) in data.item.categories"
                :key="i"
                triggers="hover"
                class="pull-up"
                variant="success"
                v-b-tooltip.hover.top="
                  `${category.name} | Desconto: ${category.min} à ${category.max}%`
                "
              >
                {{ getFirstLetters(category.initials) }}
              </b-avatar>
            </b-avatar-group>
          </div>
        </template>

        <template #cell(actions)="data">
          <div class="d-flex-between">
            <feather-icon
              :id="`customer-${data.item.uuid}-delete-icon`"
              icon="TrashIcon"
              class="cursor-pointer cursor text-danger"
              size="16"
              @click="confirmDelete(data.item.uuid)"
            />

            <feather-icon
              :id="`customer-${data.item.uuid}-edit-icon`"
              icon="EditIcon"
              class="cursor-pointer cursor"
              size="16"
              @click="openModalEditManagerDiscount(data.item.uuid)"
            />
          </div>
        </template>
      </b-table>

      <div class="mx-2 mb-2 mt-2 paginate-area">
        <div class="row">
          <div
            class="d-flex align-items-center justify-content-center justify-content-sm-start col-md-6"
          >
            <span class=""> Foram encontrados {{ totalRows }} resultados </span>
          </div>
          <div
            class="col-md-6 d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              :limit="10"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="my-1"
              @input="updatePage()"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </div>
    </b-card>

    <b-modal
      ref="modal-create-manager-ticket-discount"
      modal-class="modal-create"
      :title="titleModal"
      centered
      hide-footer
      size="lg"
    >
      <form @submit.prevent="submitCreate">
        <div class="row">
          <div class="col-md-6">
            <div class="form-row">
              <div class="col-md-12 col-12">
                <label>
                  <i class="text-danger bi bi-record-circle"></i>
                  Usuário
                </label>
                <div class="form-group mb-2">
                  <v-select
                    label="title"
                    item-text="title"
                    item-value="code"
                    placeholder="Nome do usuário"
                    v-model="form.manager"
                    :options="optionsUsers"
                    @search="fetchUsers"
                    :class="{ 'is-invalid': $v.form.manager.$error }"
                  />
                </div>
              </div>

              <div class="col-md-12 col-12">
                <label
                  class="mb-1"
                  :class="{ 'text-danger': $v.form.units.$error }"
                >
                  <i class="text-danger bi bi-record-circle"></i>
                  Unidade(s)
                </label>
                <p>
                  Selecione 1 ou mais unidades que o usuário terá permissão de
                  conceder desconto.
                </p>

                <div
                  class="form-group"
                  v-for="(unit, index) in optionsUnits"
                  :key="index"
                >
                  <b-form-checkbox
                    name="unit"
                    :value="unit.code"
                    class="mb-1"
                    :class="`custom-control-primary`"
                    v-model="form.units"
                  >
                    {{ unit.title }}
                  </b-form-checkbox>
                </div>
              </div>

              <div class="col-md-12 col-12">
                <label class="mt-2">
                  <i class="text-danger bi bi-record-circle mb-2"></i>
                  Como o usuário será notificado?
                </label>
                <p>
                  Escolha como o usuário será notificado quando houver
                  solicitação de desconto.
                </p>
                <!-- <div class="form-group">
                  <b-form-checkbox
                    name="event-filter"
                    class="mb-1"
                    :class="`custom-control-success`"
                    switch
                    v-model="form.notification_sms"
                    :checked="form.notification_sms ? 1 : 0"
                    :value="1"
                  >
                    <i class="bi bi-chat-dots"></i>
                    SMS
                  </b-form-checkbox>
                </div> -->

                <div class="form-group">
                  <b-form-checkbox
                    name="event-filter"
                    class="mb-1"
                    :class="`custom-control-success`"
                    switch
                    v-model="form.notification_email"
                    :checked="form.notification_email ? 1 : 0"
                    :value="1"
                  >
                    <i class="bi bi-envelope"></i>
                    E-mail
                  </b-form-checkbox>
                </div>

                <div class="form-group mb-0">
                  <b-form-checkbox
                    name="event-filter"
                    class="mb-1"
                    :class="`custom-control-success`"
                    switch
                    :value="1"
                    v-model="form.notification_whatsapp"
                    :checked="form.notification_whatsapp ? 1 : 0"
                  >
                    <i class="bi bi-whatsapp"></i>
                    WhatsApp
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <b-card class="mb-0">
              <label>
                <i class="text-danger bi bi-record-circle"></i>
                Produtos - Categorias
              </label>
              <p class="mb-2">
                Você pode determinar quantos % o usuário poderá conceder
                desconto, por categoria do produto.
              </p>
              <div
                class="form-row d-flex-left mb-1"
                style="border-bottom: 1px solid #f2f2f2"
              >
                <div class="col-md-9 pb-0 pb-md-50">Categoria</div>
                <div class="col-md-3 pb-0 pb-md-50 text-center">Até %</div>
              </div>

              <div
                v-if="optionsProductCategories.length"
                class="form-group"
                :class="
                  optionsProductCategories.length - 1 === index ? 'mb-0' : ''
                "
                v-for="(item, index) in optionsProductCategories"
                :key="index"
              >
                <div class="form-row d-flex-left">
                  <div class="col-md-9">
                    {{ item.title }}
                  </div>
                  <div class="col-md-3">
                    <money
                      v-if="form.categories[index]"
                      class="form-control"
                      placeholder="0.00"
                      v-model="form.categories[index].max"
                    ></money>
                  </div>
                </div>
              </div>
            </b-card>
          </div>
        </div>
        <div class="form-row justify-content-between modal-footer-custom">
          <div class="col-md-2 col-12">
            <div class="form-group">
              <v-select
                label="title"
                :searchable="false"
                item-text="title"
                item-value="code"
                placeholder="Status"
                v-model="form.active"
                :options="optionsStatus"
                :class="{ 'is-invalid': $v.form.active.$error }"
              />
            </div>
          </div>
          <div class="col-md-4 col-12">
            <div class="form-row">
              <div class="col-md-5">
                <button
                  type="button"
                  @click="closeModalManagerDiscount"
                  class="btn btn-light btn-block"
                >
                  Fechar
                </button>
              </div>
              <div class="col-md-7">
                <button :disabled="submited" class="btn btn-success btn-block" type="submit">
                  <div v-if="submited">
                    <b-spinner small variant="light" />
                  </div>
                  <div v-else>{{ textSubmit }}</div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BBadge,
  BPagination,
  BSpinner,
  BCardText,
  BModal,
  BFormCheckbox,
  BAvatar,
  BAvatarGroup,
  VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";
import { Money } from "v-money";

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BCard,
    BTable,
    BBadge,
    BPagination,
    BSpinner,
    BCardText,
    vSelect,
    BModal,
    BFormCheckbox,
    Money,
    BAvatar,
    BAvatarGroup,
  },
  data() {
    return {
      managerDiscountUuid: "",
      money: {
        decimal: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      optionsStatus: [
        { code: 1, title: "Ativo" },
        { code: 0, title: "Inativo" },
      ],
      optionsProductCategories: [],
      optionsUsers: [],
      optionsUnits: [],
      submited: false,
      textSubmit: "Cadastrar",
      titleModal: "Cadastrar Gestor",
      managers: [],
      filter: null,
      filterOn: [],
      currentPage: 1,
      perPage: 100,
      totalRows: 0,
      rowsTable: 0,
      perPageOptions: [
        {
          title: "100",
          value: 100,
        },
        {
          title: "200",
          value: 200,
        },
      ],
      tableColumns: [
        {
          key: "full_name",
          label: "Nome",
          sortable: false,
        },
        {
          key: "units",
          label: "Unidades",
          class: "text-center",
          thClass: "text-center",
          sortable: false,
        },
        {
          key: "categories",
          label: "Categorias",
          class: "text-center",
          thClass: "text-center",
          sortable: false,
          thStyle: "width: 190px",
        },
        {
          key: "created_at",
          label: "Cadastrado",
          sortable: false,
          class: "text-center",
          thClass: "text-center",
          thStyle: "width: 190px",
        },
        {
          key: "actions",
          label: "",
          thClass: "text-center td-actions",
          thStyle: "width: 80px",
        },
      ],
      form: {
        manager: "",
        active: "",
        categories: [],
        units: [],
        notification_email: 0,
        notification_sms: 0,
        notification_whatsapp: 0,
      },
    };
  },
  methods: {
    closeModalManagerDiscount() {
      this.managerDiscountUuid = "";
      this.$refs["modal-create-manager-ticket-discount"].hide();
    },
    openModalEditManagerDiscount(uuid) {
      this.textSubmit = "Salvar";
      this.titleModal = "Editar Gestor de desconto";
      this.managerDiscountUuid = uuid;

      this.$store
        .dispatch("ManagerDiscount/show", this.managerDiscountUuid)
        .then((res) => {
          this.form = res;
          this.$refs["modal-create-manager-ticket-discount"].show();
        });
    },
    clearForm() {
      this.form = {
        manager: "",
        active: "",
        categories: this.optionsProductCategories,
        units: [],
        notification_email: 0,
        notification_sms: 0,
        notification_whatsapp: 0,
      };

      this.managerDiscountUuid = "";
    },
    async getUnits() {
      this.optionsUnits = [];

      this.$store.dispatch("Unit/forSelect").then((res) => {
        this.optionsUnits = res;
      });
    },
    async getProductCategories() {
      this.optionsProductCategories = [];
      this.$store.dispatch("Product/categories").then((res) => {
        this.optionsProductCategories = res.data;

        this.optionsProductCategories.map((element) => {
          element.min = 0;
          element.max = 0;
        });

        this.form.categories = this.optionsProductCategories;
      });
    },
    submitCreate() {
      this.$v.form.$touch();

      if (!this.$v.form.$error) {
        this.submited = true;

        if (this.managerDiscountUuid) {
          this.$store
            .dispatch("ManagerDiscount/update", {
              data: this.form,
              uuid: this.managerDiscountUuid,
            })
            .then(() => {
              this.notify(
                "Operação realizada com sucesso!",
                "UserCheckIcon",
                "success",
                ""
              );

              this.getData();
              this.$v.form.$reset;
              this.$refs["modal-create-manager-ticket-discount"].hide();
            }).finally(() => {
              this.submited = false;
            });
        } else {
          this.$store.dispatch("ManagerDiscount/create", this.form).then(() => {
            this.notify(
              "Operação realizada com sucesso!",
              "UserCheckIcon",
              "success",
              ""
            );

            this.getData();
            this.$v.form.$reset;
            this.$refs["modal-create-manager-ticket-discount"].hide();
          }).finally(() => {
            this.submited = false;
          });
        }
      }
    },
    setPerPageSelected(obj) {
      this.perPage = obj.value;
      this.getData();
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    fetchUsers(term) {
      this.optionsUsers = [];

      if (term.length > 2) {
        this.$store.dispatch("User/search", term).then((res) => {
          this.optionsUsers = res;
        });
      }
    },
    confirmDelete(uuid) {
      this.$swal({
        title: "Tem certeza?",
        text: "Só será possível deletar o usuário caso não tenha nenhum cadastro vinculado.",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero deletar!",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("ManagerDiscount/delete", { uuid: uuid })
            .then(() => {
              this.managers = [];
              this.getData();
              this.$swal({
                icon: "success",
                title: "Deletado!",
                text: "O usuário foi deletado com sucesso.",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            });
        }
      });
    },
    updatePage() {
      this.getData();
    },
    async getData() {
      this.$store
        .dispatch("ManagerDiscount/all", {
          currentPage: this.currentPage,
          perPage: this.perPage,
        })
        .then((res) => {
          if (res.data.length) {
            this.managers = res.data;
            this.dataItems = res.data;
            this.rowsTable = res.data.length;
            this.totalRows = res.meta.total;
            this.currentPage = res.meta.current_page;
          }
        });

        this.getProductCategories();

        this.getUnits();
    },
    getFirstLetters(inputString) {
      const words = inputString.split(" ");
      const firstLetters = words.map((word) => word.charAt(0).toUpperCase());
      return firstLetters.join("");
    },
  },
  validations: {
    form: {
      manager: {
        required,
      },
      active: {
        required,
      },
      units: {
        required,
      },
    },
  },
  mounted() {
    this.$root.$on("open:modal-create-manager-ticket-discount", () => {
      this.clearForm();
      this.$refs["modal-create-manager-ticket-discount"].show();
    });

    this.$root.$on("bv::modal::hidden", () => {
      this.clearForm();
    });

    this.getData();
  },
  beforeDestroy() {
    this.$root.$off("open:modal-create-manager-ticket-discount");
  },
};
</script>
