var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { staticClass: "mb-0", attrs: { "no-body": "" } },
        [
          _c("div", { staticClass: "m-2" }, [
            _c("form", { staticClass: "row justify-content-between" }, [
              _c(
                "div",
                { staticClass: "col-md-2" },
                [
                  _c("v-select", {
                    attrs: {
                      label: "title",
                      required: "",
                      options: _vm.perPageOptions,
                      clearable: false,
                    },
                    on: { input: _vm.setPerPageSelected },
                    model: {
                      value: _vm.perPage,
                      callback: function ($$v) {
                        _vm.perPage = $$v
                      },
                      expression: "perPage",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "col-md-4" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filter,
                      expression: "filter",
                    },
                  ],
                  staticClass: "d-inline-block form-control",
                  attrs: { type: "search", placeholder: "Pesquisar..." },
                  domProps: { value: _vm.filter },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.filter = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
          ]),
          _c("b-table", {
            staticClass: "position-relative",
            attrs: {
              "sticky-header": true,
              "no-border-collapse": true,
              items: _vm.managers,
              responsive: "",
              fields: _vm.tableColumns,
              "primary-key": "id",
              "show-empty": "",
              "empty-text": "Nenhum registro encontrado",
              "empty-filtered-text": "Nenhum registro encontrado",
              filter: _vm.filter,
              "filter-included-fields": _vm.filterOn,
              striped: "",
              hover: "",
            },
            on: { filtered: _vm.onFiltered },
            scopedSlots: _vm._u([
              {
                key: "cell(created_at)",
                fn: function (data) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm._f("datePT")(data.item.created_at)) + " "
                    ),
                  ]
                },
              },
              {
                key: "cell(units)",
                fn: function (data) {
                  return [
                    _c(
                      "div",
                      { staticClass: "d-flex-center" },
                      [
                        data.item.units.length > 1
                          ? _c(
                              "b-avatar-group",
                              { staticClass: "ml-1", attrs: { size: "26" } },
                              _vm._l(data.item.units, function (unit, i) {
                                return _c(
                                  "b-avatar",
                                  {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover.top",
                                        value: "" + unit.name,
                                        expression: "`${unit.name}`",
                                        modifiers: { hover: true, top: true },
                                      },
                                    ],
                                    key: i,
                                    staticClass: "pull-up",
                                    attrs: {
                                      triggers: "hover",
                                      variant: "warning",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.getFirstLetters(unit.name)) +
                                        " "
                                    ),
                                  ]
                                )
                              }),
                              1
                            )
                          : _c(
                              "div",
                              _vm._l(data.item.units, function (unit, i) {
                                return _c(
                                  "b-avatar",
                                  {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover.top",
                                        value: "" + unit.name,
                                        expression: "`${unit.name}`",
                                        modifiers: { hover: true, top: true },
                                      },
                                    ],
                                    key: i,
                                    staticClass: "pull-up",
                                    attrs: {
                                      triggers: "hover",
                                      variant: "warning",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.getFirstLetters(unit.name)) +
                                        " "
                                    ),
                                  ]
                                )
                              }),
                              1
                            ),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "cell(full_name)",
                fn: function (data) {
                  return [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover.top",
                            value: data.item.active ? "Ativo" : "Inativo",
                            expression:
                              "data.item.active ? 'Ativo' : 'Inativo'",
                            modifiers: { hover: true, top: true },
                          },
                        ],
                        staticClass: "d-flex-left",
                      },
                      [
                        _c("i", {
                          staticClass: "bi bi-circle-fill mr-50",
                          class: data.item.active
                            ? "text-success"
                            : "text-danger",
                          staticStyle: { "font-size": "0.7rem" },
                        }),
                        _c("span", [
                          _vm._v(" " + _vm._s(data.item.full_name) + " "),
                        ]),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "cell(categories)",
                fn: function (data) {
                  return [
                    _c(
                      "div",
                      { staticClass: "d-flex-center" },
                      [
                        _c(
                          "b-avatar-group",
                          { staticClass: "ml-1", attrs: { size: "26" } },
                          _vm._l(data.item.categories, function (category, i) {
                            return _c(
                              "b-avatar",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover.top",
                                    value:
                                      category.name +
                                      " | Desconto: " +
                                      category.min +
                                      " à " +
                                      category.max +
                                      "%",
                                    expression:
                                      "\n                `${category.name} | Desconto: ${category.min} à ${category.max}%`\n              ",
                                    modifiers: { hover: true, top: true },
                                  },
                                ],
                                key: i,
                                staticClass: "pull-up",
                                attrs: {
                                  triggers: "hover",
                                  variant: "success",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getFirstLetters(category.initials)
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "cell(actions)",
                fn: function (data) {
                  return [
                    _c(
                      "div",
                      { staticClass: "d-flex-between" },
                      [
                        _c("feather-icon", {
                          staticClass: "cursor-pointer cursor text-danger",
                          attrs: {
                            id: "customer-" + data.item.uuid + "-delete-icon",
                            icon: "TrashIcon",
                            size: "16",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.confirmDelete(data.item.uuid)
                            },
                          },
                        }),
                        _c("feather-icon", {
                          staticClass: "cursor-pointer cursor",
                          attrs: {
                            id: "customer-" + data.item.uuid + "-edit-icon",
                            icon: "EditIcon",
                            size: "16",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.openModalEditManagerDiscount(
                                data.item.uuid
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("div", { staticClass: "mx-2 mb-2 mt-2 paginate-area" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-center justify-content-sm-start col-md-6",
                },
                [
                  _c("span", {}, [
                    _vm._v(
                      " Foram encontrados " +
                        _vm._s(_vm.totalRows) +
                        " resultados "
                    ),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "col-md-6 d-flex align-items-center justify-content-center justify-content-sm-end",
                },
                [
                  _c("b-pagination", {
                    staticClass: "my-1",
                    attrs: {
                      "total-rows": _vm.totalRows,
                      "per-page": _vm.perPage,
                      limit: 10,
                      "first-number": "",
                      "last-number": "",
                      "prev-class": "prev-item",
                      "next-class": "next-item",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.updatePage()
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "prev-text",
                        fn: function () {
                          return [
                            _c("feather-icon", {
                              attrs: { icon: "ChevronLeftIcon", size: "18" },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "next-text",
                        fn: function () {
                          return [
                            _c("feather-icon", {
                              attrs: { icon: "ChevronRightIcon", size: "18" },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.currentPage,
                      callback: function ($$v) {
                        _vm.currentPage = $$v
                      },
                      expression: "currentPage",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "modal-create-manager-ticket-discount",
          attrs: {
            "modal-class": "modal-create",
            title: _vm.titleModal,
            centered: "",
            "hide-footer": "",
            size: "lg",
          },
        },
        [
          _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submitCreate.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-6" }, [
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "col-md-12 col-12" }, [
                      _c("label", [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _vm._v(" Usuário "),
                      ]),
                      _c(
                        "div",
                        { staticClass: "form-group mb-2" },
                        [
                          _c("v-select", {
                            class: { "is-invalid": _vm.$v.form.manager.$error },
                            attrs: {
                              label: "title",
                              "item-text": "title",
                              "item-value": "code",
                              placeholder: "Nome do usuário",
                              options: _vm.optionsUsers,
                            },
                            on: { search: _vm.fetchUsers },
                            model: {
                              value: _vm.form.manager,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "manager", $$v)
                              },
                              expression: "form.manager",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-md-12 col-12" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "mb-1",
                            class: { "text-danger": _vm.$v.form.units.$error },
                          },
                          [
                            _c("i", {
                              staticClass: "text-danger bi bi-record-circle",
                            }),
                            _vm._v(" Unidade(s) "),
                          ]
                        ),
                        _c("p", [
                          _vm._v(
                            " Selecione 1 ou mais unidades que o usuário terá permissão de conceder desconto. "
                          ),
                        ]),
                        _vm._l(_vm.optionsUnits, function (unit, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "form-group" },
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  staticClass: "mb-1",
                                  class: "custom-control-primary",
                                  attrs: { name: "unit", value: unit.code },
                                  model: {
                                    value: _vm.form.units,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "units", $$v)
                                    },
                                    expression: "form.units",
                                  },
                                },
                                [_vm._v(" " + _vm._s(unit.title) + " ")]
                              ),
                            ],
                            1
                          )
                        }),
                      ],
                      2
                    ),
                    _c("div", { staticClass: "col-md-12 col-12" }, [
                      _c("label", { staticClass: "mt-2" }, [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle mb-2",
                        }),
                        _vm._v(" Como o usuário será notificado? "),
                      ]),
                      _c("p", [
                        _vm._v(
                          " Escolha como o usuário será notificado quando houver solicitação de desconto. "
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "mb-1",
                              class: "custom-control-success",
                              attrs: {
                                name: "event-filter",
                                switch: "",
                                checked: _vm.form.notification_email ? 1 : 0,
                                value: 1,
                              },
                              model: {
                                value: _vm.form.notification_email,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "notification_email", $$v)
                                },
                                expression: "form.notification_email",
                              },
                            },
                            [
                              _c("i", { staticClass: "bi bi-envelope" }),
                              _vm._v(" E-mail "),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "form-group mb-0" },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "mb-1",
                              class: "custom-control-success",
                              attrs: {
                                name: "event-filter",
                                switch: "",
                                value: 1,
                                checked: _vm.form.notification_whatsapp ? 1 : 0,
                              },
                              model: {
                                value: _vm.form.notification_whatsapp,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "notification_whatsapp",
                                    $$v
                                  )
                                },
                                expression: "form.notification_whatsapp",
                              },
                            },
                            [
                              _c("i", { staticClass: "bi bi-whatsapp" }),
                              _vm._v(" WhatsApp "),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "col-md-6" },
                  [
                    _c(
                      "b-card",
                      { staticClass: "mb-0" },
                      [
                        _c("label", [
                          _c("i", {
                            staticClass: "text-danger bi bi-record-circle",
                          }),
                          _vm._v(" Produtos - Categorias "),
                        ]),
                        _c("p", { staticClass: "mb-2" }, [
                          _vm._v(
                            " Você pode determinar quantos % o usuário poderá conceder desconto, por categoria do produto. "
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "form-row d-flex-left mb-1",
                            staticStyle: {
                              "border-bottom": "1px solid #f2f2f2",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "col-md-9 pb-0 pb-md-50" },
                              [_vm._v("Categoria")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-md-3 pb-0 pb-md-50 text-center",
                              },
                              [_vm._v("Até %")]
                            ),
                          ]
                        ),
                        _vm._l(
                          _vm.optionsProductCategories,
                          function (item, index) {
                            return _vm.optionsProductCategories.length
                              ? _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass: "form-group",
                                    class:
                                      _vm.optionsProductCategories.length -
                                        1 ===
                                      index
                                        ? "mb-0"
                                        : "",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "form-row d-flex-left" },
                                      [
                                        _c("div", { staticClass: "col-md-9" }, [
                                          _vm._v(
                                            " " + _vm._s(item.title) + " "
                                          ),
                                        ]),
                                        _c(
                                          "div",
                                          { staticClass: "col-md-3" },
                                          [
                                            _vm.form.categories[index]
                                              ? _c("money", {
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    placeholder: "0.00",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form.categories[index]
                                                        .max,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form.categories[
                                                          index
                                                        ],
                                                        "max",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.categories[index].max",
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e()
                          }
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "form-row justify-content-between modal-footer-custom",
                },
                [
                  _c("div", { staticClass: "col-md-2 col-12" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("v-select", {
                          class: { "is-invalid": _vm.$v.form.active.$error },
                          attrs: {
                            label: "title",
                            searchable: false,
                            "item-text": "title",
                            "item-value": "code",
                            placeholder: "Status",
                            options: _vm.optionsStatus,
                          },
                          model: {
                            value: _vm.form.active,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "active", $$v)
                            },
                            expression: "form.active",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "col-md-4 col-12" }, [
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "col-md-5" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-light btn-block",
                            attrs: { type: "button" },
                            on: { click: _vm.closeModalManagerDiscount },
                          },
                          [_vm._v(" Fechar ")]
                        ),
                      ]),
                      _c("div", { staticClass: "col-md-7" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-success btn-block",
                            attrs: { disabled: _vm.submited, type: "submit" },
                          },
                          [
                            _vm.submited
                              ? _c(
                                  "div",
                                  [
                                    _c("b-spinner", {
                                      attrs: { small: "", variant: "light" },
                                    }),
                                  ],
                                  1
                                )
                              : _c("div", [_vm._v(_vm._s(_vm.textSubmit))]),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }